<template>
    <base-dialog width="500" :title="title" :is-open.sync="isOpenLocal">
        <v-card-text class="py-7">
            <span class="body-2 font-weight-bold">
                {{ description }}
            </span>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            rounded
                            color="#389877"
                            :loading="loading"
                            @click="$emit('onProceed')"
                            >Proceed</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";

export default {
    name: "base-alert-dialog",

    components: { BaseDialog },

    props: {
        isOpen: Boolean,
        title: String,
        description: String,
        theme: String,
        loading: Boolean,
    },

    data() {
        return {
            isOpenLocal: this.isOpen,
        };
    },

    computed: {
        color() {
            let color = null;
            if (this.theme === "primary") color = "primary";
            else if (this.theme === "success") color = "success";
            else if (this.theme === "warning") color = "error";
            return color;
        },
    },

    watch: {
        isOpen(value) {
            this.isOpenLocal = value;
        },
        isOpenLocal(value) {
            this.$emit("update:isOpen", value);
        },
    },
};
</script>
